<template>
  <div style="min-width: 1360px">
    <Header/>
    <headerBanner :content="bannerContent" title="财务管理" @btnClick="bannerClick" imgStyle="height:0%;height:0"
                  contentStyle="width:100%" :bodyStyle="bodyStyle"/>
    <div class="card">
      <div class="card-container">
        <div class="head-card-title">为企业解决财务难题</div>
        <div style="display: flex;align-items: center;justify-content: space-between;flex-wrap: wrap;">
          <div v-for="(item,i) in dataList" :key="i" class="cell-card">
            <img :src="item.img" style="width: 100%;height: 130px;">
            <div class="cell-card-title">{{ item.title }}</div>
            <div class="cell-card-content">{{ item.content }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="card" style="background-color: #F7F8FE;">
      <div class="card-container">
        <div class="center-content">
          <div class="left">
            <div>
              <div class="left-title">企业省钱</div>
              <div class="left-tips">管控超支规避浪费，促进落地企业数字转型</div>
            </div>
            <div>
              <div class="left-title">财务省时</div>
              <div class="left-tips">统一开发票，一张报表看清各项费用支出</div>
            </div>
            <div>
              <div class="left-title">员工省事</div>
              <div class="left-tips">无需垫资无需贴票，一个网站搞定所有因公消费</div>
            </div>
          </div>
          <div>
            <img :src="require('@/assets/img/home/LeiurYjJcv.png')" alt=""
                 style="width: 774px;height: 397px;object-fit: cover;">
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-container">
        <div style="font-size: 24px;font-weight: bold;color: #333;margin-bottom: 14px;">数智财务服务的价值</div>
        <div style="font-size: 16px;color: #666;">财务共享平台，助力实现财务向业财一体化转变，重复性作业标准化、流水化，一站式打通全流程、全系统</div>
        <div style="display: flex;align-items: center;flex-wrap: wrap;justify-content: space-between;margin-top: 20px;">
          <div class="card2" v-for="(item,i) in dataList2" :key="i">
            <img :src="item.img" style="height: 100px;">
            <div style="font-size: 18px;margin-top: 4px;">{{ item.title }}</div>
            <div style="text-align: center;font-size: 14px;margin-top: 12px;">{{ item.content }}</div>
          </div>
        </div>
      </div>
    </div>
    <applyTrial ref="dialog" :servicePlatform="'财务管理系统'"/>
    <Footer/>
  </div>
</template>
<script>
import Header from "@/views/home/newCloudeHeader.vue";
import applyTrial from "@/components/assembly/applyTrial";
import Footer from "@/views/home/newCloudeFooter.vue";
import headerBanner from '@/components/assembly/headerBanner';

export default {
  components: {Header, applyTrial, Footer, headerBanner},
  data() {
    return {
      bannerContent: '融合最新的财务管理模式、数智技术与企业最佳实践，构建费用管理、财务会计、财务共享、财务中台、税务管理、管理会计、企业绩效、资金管理等新一代财务云应用，助力企业财务数字化转型。',
      bodyStyle: `background-image:url(${require('@/assets/img/home/WDKI0LH_y.png')});background-size: contain;background-repeat: no-repeat;background-position: right 25px;`,
      dataList: [
        {
          img: require('@/assets/img/home/finance_card_1.png'),
          title: '移动查账，智能记账',
          content: '随查随用，关键数据及时掌握；拍照、扫码识别发票，并根据用户记账习惯智能匹配凭证模板，智能记账'
        },
        {
          img: require('@/assets/img/home/finance_card_2.png'),
          title: '一键生成凭证，让财务更简单',
          content: '拍照、扫码查验发票，根据用户习惯匹配模板智能记账，避免重复记账；凭证附件电子化存储；业务单据、日记账、固定资产、工资一键生成凭证，减少财务工作量'
        },
        {
          img: require('@/assets/img/home/finance_card_3.png'),
          title: '多维账表分析，助力经营决策',
          content: '数量、外币、核算项目多维账表查询；企业经营状况一目了然，满足企业精细化核算需求'
        },
        {
          img: require('@/assets/img/home/finance_card_4.png'),
          title: '财税一体省时省力',
          content: '一键导入发票，解决票财脱节的难题；发票自动验真，发票验真查重；自动获取发票影像，自动形成凭证附件；一键生成凭证，实现财税无缝连接'
        },
        {
          img: require('@/assets/img/home/finance_card_5.png'),
          title: '全面资金管控，掌握企业资金流',
          content: '银企互联，下载银行流水，实时查询余额；收支汇总表，全面掌握资金收支，规避资金风险；独立于财务结账，实时锁定业务，使权责明确化'
        },
        {
          img: require('@/assets/img/home/finance_card_6.png'),
          title: '资产卡片式管理，自动计提折旧',
          content: '固定资产卡片式管理，自动计提折旧，生成购入、折旧、减值、清理凭证；部门、资产类别多维度折旧报表'
        },
        {
          img: require('@/assets/img/home/finance_card_7.png'),
          title: '一键生成工资凭证',
          content: '智能导入工资表，生成计提工资、发放工资凭证；工资统计直观清晰'
        },
        {
          img: require('@/assets/img/home/finance_card_8.png'),
          title: '一键报税，省心省力',
          content: '一键报税直达税局，省去手工填报的繁琐'
        }
      ],
      dataList2: [
        {
          img: require('@/assets/img/home/jz01.png'),
          title: '战略支撑',
          content: '增强企业分支机构复制能力，有助于快速扩张，更快建立新业务'
        },
        {
          img: require('@/assets/img/home/jz02.png'),
          title: '财务转型',
          content: '释放财务人员精力，从事更高价值的工作'
        },
        {
          img: require('@/assets/img/home/jz03.png'),
          title: '成本控制',
          content: '降低财务运营成本，提高人均效能'
        },
        {
          img: require('@/assets/img/home/jz04.png'),
          title: '风险管控',
          content: '分散活动和资源得到有效整合，集中管理有利于风险管控'
        },
        {
          img: require('@/assets/img/home/jz05.png'),
          title: '提升质效',
          content: '提供统一、标准、规范、高效的财务交易处理'
        },
        {
          img: require('@/assets/img/home/jz06.png'),
          title: '人才培养',
          content: '团队分层建设，搭建适应企业发展需求的人才梯队'
        }
      ]
    }
  },
  methods: {
    bannerClick() {
      this.$refs.dialog.show = true;
    }
  }
}
</script>
<style lang="scss" scoped>
.card {
  padding: 60px 0;

  &-container {
    width: 1200px;
    margin: auto;
  }
}

.head-card-title {
  font-weight: bold;
  font-size: 30px;
  color: #333;
  margin-bottom: 30px;
}

.cell-card {
  width: 290px;
  height: 335px;
  margin-top: 10px;
  border: 1px solid #EAEDFF;
  padding: 20px;
  box-sizing: border-box;

  &-title {
    font-size: 16px;
    line-height: normal;
    font-weight: bold;
    margin-top: 18px;
    margin-bottom: 16px;
  }

  &-content {
    font-size: 14px;
    line-height: 1.5;
    color: #333;
    text-align: justify;
  }
}

.center-content {
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  .left {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    .left-title {
      font-size: 24px;
      font-weight: bold;
      color: #333;
    }

    .left-tips {
      font-size: 16px;
      color: #666;
      margin-top: 14px;
    }
  }
}

.card2 {
  width: 390px;
  height: 246px;
  border: 1px solid #EAEDFF;
  margin-top: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  box-sizing: border-box;
  padding-left: 30px;
  padding-right: 30px;
}
</style>
